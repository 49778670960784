/**
  * to have a responsive table, use the global `table-responsive` class on the parent container
  * to have an elevated table, use the global `table-elevated` class on the parent container
  */

/* Native HTML table structure */
table {
  border-spacing: 0;
  @apply w-full;
  @apply divide-y divide-grey-300;
}

th {
  @apply px-md py-sm;
  @apply text-left font-medium text-president-400;
  font-size: 12px;
  line-height: 1.125rem;
}

tbody {
  @apply divide-y divide-grey-200;
}

tbody td {
  @apply px-md py-xs;
  @apply truncate whitespace-nowrap;
}

.cft-table-sticky {
  /*
  Note that the table can either set this class or an inline style to make something sticky.
  We set the style as `!important` so that we get an identical specificity in both cases
  and to avoid cases where user styles have a higher specificity.
 */
  position: sticky !important;
}

.cft-table-sticky.cft-table-sticky-background {
  @apply border-r border-r-grey-200 bg-grey-50;
}

.cft-table-fixed-layout {
  table-layout: fixed;
}

/** Use this class to get the elevated style on a table */
.table-elevated {
  @apply rounded-2 ring-1 ring-grey-300;
  @apply z-50;
}

/** Use this class to get the responsive tables that will show an horizontal scroll bar when necessary */
.table-responsive {
  @apply overflow-x-auto;
}
